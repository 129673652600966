export const RESET_META_TITLE = "RESET_META_TITLE"

// for add level editing.
export const ADD_NEW_SECTION = "ADD_NEW_SECTION";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";

export const ADD_DATA_TO_SECTION = "ADD_DATA_TO_SECTION"; 
export const ADD_SOCIAL_DATA_TO_SECTION = "ADD_SOCIAL_DATA_TO_SECTION"; 
export const REMOVE_DATA_FROM_SECTION = "REMOVE_DATA_FROM_SECTION";
export const UPDATE_DATA_FORM_SECTION = "UPDATE_DATA_FORM_SECTION";

// for loading purposes.
export const LOAD_HEY_SECTIONS = "LOAD_HEY_SECTIONS";

export const SET_HEY_THEME = "SET_HEY_THEME";

// Hey Bio Profile

export const SET_HEY_PROFILE_ID = "SET_HEY_PROFILE_ID"
export const SET_HEY_ID = "SET_HEY_ID";
export const SET_HEY_NAME = "SET_HEY_NAME";
export const SET_HEY_DESCRIPTION = "SET_HEY_DESCRIPTION";
export const SET_HEY_IMAGE = "SET_HEY_IMAGE";
export const CLEAR_HEY_PROFILE_DATA = "CLEAR_HEY_PROFILE_DATA";
export const SET_HEYBIO_LOADING = "SET_HEYBIO_LOADING";
export const SET_PAGE_ID = "SET_PAGE_ID";

export const SET_SECTIONS = "SET_SECTIONS";
export const SET_SECTION_DATA = "SET_SECTION_DATA";
export const SET_RELATED_ID = "SET_RELATED_ID"