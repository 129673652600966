import store from "../../store";
import {
  ADD_DATA_TO_SECTION,
  REMOVE_DATA_FROM_SECTION,
  UPDATE_DATA_FORM_SECTION,
  ADD_SOCIAL_DATA_TO_SECTION,
} from "../../ActionType/HeyBioActionTypes";

import { deleteHeyPageSectionData } from "../HeyBioActions/HeyActions";

const { dispatch } = store;

/**
 * atomic call to add the links or section content.
 * {
 *  sectionId:"",
 *  type:"",
 *  args: "",
 * }
 * @returns
 */
export const addDataToSection = (data, linkIdx) => {
  if (!data.type) return;
  dispatch({ type: ADD_DATA_TO_SECTION, data: data, linkIdx: linkIdx });
  return;
};
export const addSocialDataToSection = (data, sectionIdx) => {
  dispatch({ type: ADD_SOCIAL_DATA_TO_SECTION, data: data, sectionIdx: sectionIdx });
  return;
};

/**
 * atomic call to remove the links or section content.
 * {
 *  sectionId: "",
 *  id: ""
 * }
 * @returns
 */
export const removeDataFormSection = async (data) => {
  const res = await deleteHeyPageSectionData(data.sectionId, data.sectionIdx, data.id)
  if(res)dispatch({ type: REMOVE_DATA_FROM_SECTION, data: data });
  return;
};

/**
 * atomic call to update the data of the section
 *  {
 *   sectionId:"",
 *   linkId:  "",
 *   linkType:"",
 *   args: "",
 *  }
 * @returns
 */
export const updateDataInSection = () => {
  return;
};
