export const OWNER = "owner";
export const ANY_ROLE = "ANY_ROLE";

//tracking types
export const PAGE_VIEW = "PAGE_VIEW";
export const CLICK = "CLICK";
export const PRODUCT_TRY = "PRODUCT_TRY";
export const APP_REDIRECT = "APP_REDIRECT";
export const LOGIN = "LOGIN";

// login challenges
export const REGISTER = "REGISTER";
export const EMAIL_OTP = "EMAIL_OTP";
export const PASSWORD = "PASSWORD";
export const REGISTER_ORG = "REGISTER_ORG";


// Hey Bio
export const BACKGROUND = "BACKGROUND"
export const FONT_FAMILY = "FONT_FAMILY"
export const LINK = "LINK"
export const THEME = "THEME"
export const BANNER = "BANNER"
export const SOCIALS = "SOCIALS"
export const SANS_SERIF_FONTS = "SANS_SERIF_FONTS"
export const SERIF_FONTS = "SERIF_FONTS"
export const LINKS = "LINKS";
export const POSTS = "POSTS";
export const SOCIAL_LINK = "SOCIAL_LINK";
export const EMBEDDED_CONTENT="EMBEDDED_CONTENT"
export const PROPERTIES = "PROPERTIES"