import React from "react"
import { Grid } from "@awesomesuite-frontend/awesome-nebula"
import useStyles from "./HeySocialStyles"
import { socialLinkData } from "../Data/SocialLinkData"

const HeySocial = ({ links, theme, themeVariables }) => {
    const linksMap = {
        behance: {
            Icon: socialLinkData[themeVariables.socialType]?.behance,
            prefix: "https://",
        },
        dribble: {
            Icon: socialLinkData[themeVariables.socialType]?.dribble,
            prefix: "https://",
        },
        facebook: {
            Icon: socialLinkData[themeVariables.socialType]?.facebook,
            prefix: "https://",
        },
        instagram: {
            Icon: socialLinkData[themeVariables.socialType]?.instagram,
            prefix: "https://",
        },
        linkedId: {
            Icon: socialLinkData[themeVariables.socialType]?.linkedin,
            prefix: "https://",
        },
        email: {
            Icon: socialLinkData[themeVariables.socialType]?.mail,
            prefix: "mailto:",
        },
        phoneNumber: {
            Icon: socialLinkData[themeVariables.socialType]?.phone,
            prefix: "tel:",
        },
        messanger: {
            Icon: socialLinkData[themeVariables.socialType]?.messenger,
            prefix: "https://",
        },
        pinterest: {
            Icon: socialLinkData[themeVariables.socialType]?.pinterest,
            prefix: "https://",
        },
        reddit: {
            Icon: socialLinkData[themeVariables.socialType]?.reddit,
            prefix: "https://",
        },
        snapchat: {
            Icon: socialLinkData[themeVariables.socialType]?.snapchat,
            prefix: "https://",
        },
        soundcloud: {
            Icon: socialLinkData[themeVariables.socialType]?.soundcloud,
            prefix: "https://",
        },
        spotify: {
            Icon: socialLinkData[themeVariables.socialType]?.spotify,
            prefix: "https://",
        },
        telegram: {
            Icon: socialLinkData[themeVariables.socialType]?.telegram,
            prefix: "https://",
        },
        tiktok: {
            Icon: socialLinkData[themeVariables.socialType]?.tiktok,
            prefix: "https://",
        },
        twitter: {
            Icon: socialLinkData[themeVariables.socialType]?.twitter,
            prefix: "https://",
        },
        whatsapp: {
            Icon: socialLinkData[themeVariables.socialType]?.whatsapp,
            prefix: "https://wa.me/",
        },
        youtube: {
            Icon: socialLinkData[themeVariables.socialType]?.youtube,
            prefix: "https://",
        },
    }
    
    const classes = useStyles()
    return (
        <Grid className={classes.heyTSocial}>
            <div className={classes.socialWrapper}>
                {links &&
                    links.map((item) => {
                        let ICON = ""
                        const { Icon } = linksMap[item.skey ?? "phoneNumber"]
                        ICON = Icon
                        return (
                            <a
                                href={`${linksMap[item.skey]?.prefix ?? ""}${
                                    item.link
                                }`}
                                className={classes.socialLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {ICON}
                            </a>
                        )
                    })}
            </div>
        </Grid>
    )
}

export default HeySocial
