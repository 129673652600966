import React from "react"
import useStyles from "./HeyEmbedStyles"

const HeyEmbed = ({ data, style, theme = "greyp", themeVariables, fromMobilePreview }) => {
    const classes = useStyles({
        height: data?.height,
        theme: theme,
        themeVariables: themeVariables,
        fromMobilePreview: fromMobilePreview,
        type: data?.type,
    })
    const { title, link } = data
    return (
        <div className={classes.embedWrapper} style={{ ...style }}>
            <iframe
                className={classes.iframeTag}
                src={link}
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                height={data?.type === "youtube" ? fromMobilePreview ? "160px" : "400px" : data?.type === "spotify" ? "152px" : "166px"}
            ></iframe>
        </div>
    )
}

export default HeyEmbed
