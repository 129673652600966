import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    heyBannerWrapper: {
        display: "block",
    },
    heyTbanner: {
        borderRadius: ({ theme }) => theme.bannerRadius,
        display: "flex",
        border: ({ theme }) => theme.bannerBorder ?? "none",
        background: ({ theme }) => theme.bannerBG ?? theme.secondaryColor,
        boxShadow: ({ theme }) => theme.bannerShadow,
        overflow: "hidden",
        borderColor: ({ theme }) => theme.borderColor2,

        flexDirection: "column",
    },
    heyTBannerHorizontal: {
        flexDirection: "row",
    },
    heyTbannerImg: {
        borderRadius: ({ theme }) => theme.boxImageBorderRadius,

        minHeight: "55%",
        maxHeight: "55%",

        background: "transparent",
        padding: ({ theme }) => theme.bannerPadding,

        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
    },
    heyTbannerImgHorizontal: {
        minWidth: "50%",
        maxWidth: "50%",
        minHeight: "100%",
        maxHeight: "100%",
    },
    heyTbannerContent: {
        flex: "1",
        padding: "10px 20px",
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(45%)",
    },
    heyTbannerContentHorizontal: {
        padding: "20px 20px",
        maxHeight: "calc(100%)",
    },
    heyTbannerTitle: {
        color: ({ theme }) => theme.textColor2,
        textAlign: "center",
        marginBottom: 6,
        fontFamily: ({ themeVariables }) => themeVariables.fontType,
        fontSize: "0.9rem",
        overflowWrap: "anywhere",
    },
    heyTBannerTitleHorizontal: {
        display: "-webkit-box",
        boxOrient: "vertical",
        textAlign: "left",
        lineClamp: 2,
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    heyTbannerDes: {
        color: ({ theme }) => theme.textColor2,
        textAlign: "center",
        fontFamily: ({ theme }) => theme.fontFamily,
        fontSize: 14,
        lineHeight: "1.1rem",
        fontSize: "0.75rem",
        fontFamily: ({ themeVariables }) => themeVariables.fontType,
        marginBottom: "0",
        overflowWrap: "anywhere",
    },
    heyTbannerDesHorizontal: {
        display: "-webkit-box",
        boxOrient: "vertical",
        textAlign: "left",
        lineClamp: 5,
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    heyTbannerBtn: {
        margin: "0 auto",
        marginTop: "auto",
        width: "fit-content",
        minWidth: "120px",
        color: ({theme}) => theme.textColor3,
        padding: "8px 12px",
        border: ({ theme }) => theme.boxButtonBorder && `1px solid ${theme.borderColor3}`,
        borderRadius: ({ theme }) => theme.bannerButtonRadius,
        display: "block",
        textAlign: "center",
        textDecoration: "none",
        background: ({ btnColor, theme }) => btnColor || theme.accentColor2,
        fontFamily: ({ themeVariables }) => themeVariables.fontType,
        fontWeight: "800",
    },
})
export default useStyles
