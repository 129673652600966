import React, { useEffect, useState } from "react";
import "../../App.css";
import { connect } from "react-redux";
import { Grid } from "@awesomesuite-frontend/awesome-nebula";
import { ThemeProvider } from "react-jss";

import useStyles from "./FullPageViewStyles";
import {
  resetMetaTitle,
  getHeyBioProfileByHeyIdPublic,
} from "../../Actions/HeyBioActions/HeyActions";

import HeyBanner from "../HeyBanner";
import HeyLink from "../HeyLink";
import HeySocial from "../HeySocial";
import HeyEmbed from "../HeyEmbed";
import Avatar from "../Avatar/Avatar";

import { ReactComponent as BySocial } from "../../Pages/HeyBio/awesomesocial.svg";
import noProfilePic from "../../assets/icons/_no_profile_pic.png";
import {
  BANNER,
  LINKS,
  POSTS,
  SOCIAL_LINK,
  EMBEDDED_CONTENT,
} from "../../constants";

/**
 * get the component for the section type
 * @returns
 */
const getSectionComponent = (type) => {
  switch (type) {
    case BANNER:
      return HeyBanner;
    case LINKS:
      return HeyLink;
    case SOCIAL_LINK:
      return HeySocial;
    case EMBEDDED_CONTENT:
      return HeyEmbed;
    case POSTS:
      break;
    default:
      break;
  }
  return null;
};

const SectionsPreview = ({
  sections,
  sectionData,
  themeVariables,
  classes,
  theme,
}) => {
  if (!sections) return;

  return (
    <>
      {sections.map((item, index) => {
        const Comp = getSectionComponent(item.type);

        if (item.type === SOCIAL_LINK)
          return (
            <Comp
              links={sectionData[item.id]}
              key={index}
              theme={theme}
              themeVariables={themeVariables}
            />
          );

        if (item.type === BANNER) {
          return (
            <Grid
              container
              item
              xs={11}
              margin="0px auto"
              padding={12}
              key={index}
              className={classes.bannerSectionWrapper}
            >
              {sectionData[item.id]?.map((data, key) => (
                <div
                  className={`${classes.sectionItem} ${
                    item.type === LINKS ? classes.wrap : ""
                  }`}
                >
                  <Comp
                    themeVariables={themeVariables}
                    {...data}
                    key={key}
                    theme={theme}
                    data={data}
                  />
                </div>
              ))}
            </Grid>
          );
        }

        return (
          <Grid
            container
            item
            xs={11}
            margin="0px auto"
            padding={12}
            key={index}
            className={classes.sectionWrapper}
          >
            <li
              className={`${classes.sectionItem} ${
                item.type === LINKS || item.type === EMBEDDED_CONTENT
                  ? classes.wrap
                  : ""
              }`}
            >
              {sectionData[item.id]?.map((data, key) => (
                <Comp
                  data={data}
                  themeVariables={themeVariables}
                  {...data}
                  key={key}
                  theme={theme}
                />
              ))}
            </li>
          </Grid>
        );
      })}
    </>
  );
};

const FullPageView = ({
  theme,
  sections,
  sectionData,
  profileData,
  themeVariables,
  heyId,
}) => {
  const classes = useStyles(theme);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadPageData = async () => {
      setLoading(true);
      await getHeyBioProfileByHeyIdPublic(heyId);
      setLoading(false);
    };
    loadPageData();
  }, [heyId]);

  resetMetaTitle(`${profileData.name} | Hey.Bio`);

  if (loading) {
    return (
      <div className={classes.loadingWrapper}>
        <div className={`${classes.wavingHand} waveAnimation`}>👋</div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid className={classes.heyPreviewContainer}>
        <Grid container className={classes.heyPreviewWrapper}>
          <Grid
            item
            xs={12}
            sm={5}
            md={6}
            margin="0 auto"
            className={classes.heyPreviewContent}
          >
            <Grid className={classes.profileDataWrapper} padding={"1rem"}>
              <Grid container justify="center">
                <Avatar
                  icon={profileData.image ? profileData.image : noProfilePic}
                  width={80}
                  height={80}
                  user="asd"
                />
              </Grid>
              <p className={classes.pReviewUserName}>{profileData.name}</p>
              <p className={classes.pReviewDesc}>{profileData.desc}</p>
            </Grid>
            <SectionsPreview
              sections={sections}
              sectionData={sectionData}
              themeVariables={themeVariables}
              theme={theme}
              classes={classes}
            />
          </Grid>
          <div style={{ height: "3rem" }}></div>
          {/*For the bottom banner as it has position fixed*/}
        </Grid>
      </Grid>
      <div className={classes.socialPowered}>
        <a
          className={classes.socialPoweredContent}
          href="https://awesomesuite.com/social/hey-bio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classes.text}>⚡ by </div>
          <BySocial style={{ width: "250px", height: "auto" }} />
        </a>
      </div>
    </ThemeProvider>
  );
};

const mapStateToprops = ({ HeyBioReducer }) => ({
  themeVariables: HeyBioReducer.themeVariables,
  theme: HeyBioReducer.theme,
  sectionData: HeyBioReducer.sectionData,
  sections: HeyBioReducer.sections,
  profileData: HeyBioReducer.profileData,
});

export default connect(mapStateToprops)(FullPageView);
