import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  loadingWrapper: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wavingHand: {
    fontSize: "5rem",
    transformOrigin: "70% 70%",
    display: "inline-block",
  },
  heyPreviewContainer: {
    background: (theme) => theme.background,
  },
  heyPreviewWrapper: {
    padding: "2rem 0",
    paddingBottom: "0",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    maxWidth: "1300px",
    margin: "0 auto",
  },
  heyPreviewContent: {
    width: "100%",
    flex: "0 0 auto",
    maxWidth: "100%",
    flexShrink: "0",
    containerType: "inline-size",

    "@media (min-width: 768px)": {
      width: "88%",
    },
    "@media (min-width: 992px)": {
      width: "71%",
    },
    "@media (min-width: 1200px)": {
      width: "63%",
    },
    "@media (min-width: 1400px)": {
      width: "55%",
    },
  },
  profileDataWrapper: {
    width: "91.6667%",
    margin: "auto",

    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  pReviewUserName: {
    textAlign: "center",
    color: (theme) => theme.fontColor,
    fontSize: "24px",
    fontFamily: (theme) => theme.fontFamily,
    marginTop: "10px",
    marginBottom: "1rem",
    fontWeight: "700",
  },
  pReviewDesc: {
    textAlign: "center",
    color: (theme) => theme.fontColor,
    fontFamily: (theme) => theme.fontFamily,
    fontSize: "1rem",
  },
  sectionWrapper: {
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  bannerSectionWrapper: {
    "@media (max-width: 600px)": {
      overflowX: "auto",
      flexWrap: "nowrap",
      alignItems: "center",
      width: "96%",
      "scroll-snap-type": "x mandatory",
      "  -webkit-overflow-scrolling": "touch",
    },
    "@media (max-width: 1200px)": {
      maxWidth: "100%",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(179, 175, 175, 0.562)",
      WebkitBoxShadow: "inset 0 0 6px rgba(179, 175, 175, 0.562)",
      backgroundColor: "#fff",
      visibility: "hidden",
    },
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      backgroundColor: "#ffffff",
      visibility: "hidden",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cecfcf",
      visibility: "hidden",
    },
  },
  sectionItem: {
    listStyle: "none",
    margin: " 0 auto",
    display: "flex",
    justifyContent: "center",
    "scroll-snap-align": "start",
  },
  wrap: {
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
  },

  socialPowered: {
    width: "100%",
    margin: "auto auto 0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    bottom: 0,

    svg: {
      width: "100%",
    },
  },
  text: {
    whiteSpace: "pre",
    fontSize: "1rem",
  },
  socialPoweredContent: {
    width: "600px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    padding: "6px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    textDecoration: "none",
    color: "inherit",
    "@media (max-width: 600px)": {
      width: "400px",
    },
    "@media (max-width: 400px)": {
      width: "95%",
    },
  },
});

export default useStyles;
