import store from "../../store"
import {
    REMOVE_SECTION,
    ADD_NEW_SECTION,
    UPDATE_SECTION,
    SET_HEY_THEME,
    SET_HEY_PROFILE_ID,
    SET_HEY_ID,
    SET_HEY_NAME,
    SET_HEY_DESCRIPTION,
    SET_HEY_IMAGE,
    CLEAR_HEY_PROFILE_DATA,
    SET_HEYBIO_LOADING,
    SET_PAGE_ID,
    SET_SECTIONS,
    SET_SECTION_DATA,
    SET_RELATED_ID,
    RESET_META_TITLE,
} from "../../ActionType/HeyBioActionTypes"
import {
    BACKGROUND,
    THEME,
    BANNER,
    LINK,
    FONT_FAMILY,
    SOCIALS,
    PROPERTIES,
} from "../../constants"
import { addDataToSection, addSocialDataToSection } from "./SectionAction"
import { heyBioProfileUrl, heyBioPageUrl, heyBio } from "../../api/axios"
import axios from "axios"
import { createMessage } from "../MessageAction"

export const getHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const { dispatch, getState } = store

export const resetMetaTitle = (title) => {
  dispatch({ type: RESET_META_TITLE, payload: title });
};

/**
 * {
 *  sectionName: "....",
 *  sectionType: AASD| ASDASD| ASDASD|
 * }
 */
export const addNewSection = (data) => {
    // TODO: add new section here
    if (!data.name || !data.type || !data.id)
        throw new Error("Could not add new section")
    dispatch({ type: ADD_NEW_SECTION, data })
}

/**
 * {
 *  sectionName: "....",
 *  sectionType: AASD| ASDASD| ASDASD|
 * }
 */
export const updateSection = (data) => {
    // TODO: update new section here
    dispatch({ type: UPDATE_SECTION, data })
}

/**
 * {
 *  id: // index or id
 *  sectionName: "....",
 * }
 */
export const removeSection = async (data) => {
    const { pageId } = getState().HeyBioReducer
    const res = await deleteHeyPageSectionHandler(pageId, data)
    if (res) dispatch({ type: REMOVE_SECTION, data: data })
    // TODO: add new section here
}

/**
 * load the section change based on the api later.
 * @param {*} userId
 */
export const loadSection = (userId) => {
    // TODO: add logic for loading of the hey section.
}

// Hey Profile Actions

export const setPageId = (payload) => {
    dispatch({ type: SET_PAGE_ID, payload: payload })
}

/**
 * Set the theme of the hey bio
 * @param {*} themeName
 */
export const addHeyTheme = (payload) => {
    dispatch({ type: SET_HEY_THEME, payload: payload })
}

/**
 * Set the heyId of the hey bio
 * @param {*} id
 */
export const setHeyProfileId = (id) => {
    dispatch({ type: SET_HEY_PROFILE_ID, payload: id })
}

/**
 * Set the heyId of the hey bio
 * @param {*} heyId
 */
export const setHeyId = (heyId) => {
    dispatch({ type: SET_HEY_ID, payload: heyId })
}

/**
 * Set the heyName of the hey bio
 * @param {*} heyName
 */
export const setHeyName = (heyName) => {
    dispatch({ type: SET_HEY_NAME, payload: heyName })
}

/**
 * Set the heyDescription of the hey bio
 * @param {*} heyDesc
 */
export const setHeyDescription = (heyDesc) => {
    dispatch({ type: SET_HEY_DESCRIPTION, payload: heyDesc })
}

/**
 * Set the heyImage of the hey bio
 * @param {*} heyImage
 */
export const setHeyImage = (heyImage) => {
    dispatch({ type: SET_HEY_IMAGE, payload: heyImage })
}

export const clearHeyProfileData = (full) => {
    dispatch({ type: CLEAR_HEY_PROFILE_DATA, payload: { full: full } })
}

export const setHeyBioLoading = (status) => {
    dispatch({ type: SET_HEYBIO_LOADING, payload: status })
}

export const addHeyBioProfile = async () => {
    const { heyId, name, desc, image } = getState().HeyBioReducer.profileData
    const { related_id } = getState().HeyBioReducer
    try {
        const headers = getHeaders()
        const res = await axios.post(
            heyBioProfileUrl,
            {
                heyId: heyId,
                name: name,
                description: desc,
                image: image,
                related_id: Number(related_id),
            },
            headers
        )

        if (res.data) {
            setHeyProfileId(res.data?.model?.id)
            return res.data.model
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}

export const updateHeyBioProfile = async (updateData) => {
    const { id } = getState().HeyBioReducer.profileData
    const { heyId, name, desc, image } = updateData
    try {
        const headers = getHeaders()
        const res = await axios.put(
            heyBioProfileUrl,
            {
                heyId: heyId,
                name: name,
                description: desc,
                image: image,
            },
            headers
        )

        if (res.data.model) {
            setHeyId(heyId)
            setHeyName(name)
            setHeyDescription(desc)
            setHeyImage(image)

            createMessage("success", "Profile Updated Successfully")
            return res.data.model
        } else {
            createMessage("danger", res.data.msg)
            return null
        }
    } catch (error) {
        return null
    }
}

export const getHeyBioProfile = async (id) => {
    if (!id) return null
    dispatch({ type: SET_RELATED_ID, payload: id })
    try {
        const headers = getHeaders()
        headers.params = {
            related_id: Number(id),
        }
        const res = await axios.get(heyBioProfileUrl, headers)

        if (res.data.model) {
            const dummy = res.data.model[res.data.model.length - 1]
            setHeyProfileId(dummy.id)
            setHeyId(dummy.heyId)
            setHeyName(dummy.name)
            setHeyDescription(dummy.description)
            setHeyImage(dummy.image)
            setHeyBioLoading(false)
            return dummy
        } else {
            setHeyBioLoading(false)
            return null
        }
    } catch (error) {
        return null
    }
}

export const addHeyPage = async () => {
    const { sectionData, sections, theme, themeVariables, profileData } =
        getState().HeyBioReducer
    const themeData = {
        profileId: profileData.heyId,
        theme: themeVariables.theme,
        fontType: themeVariables.fontType,
        bannerType: themeVariables.bannerType,
        background: theme.background,
        name: profileData.name,
        linkType: themeVariables.linkType ?? "SHARP_1",
    }

    let properties = {
        socialType: themeVariables.socialType,
        ...themeVariables.properties,
    }

    const headers = getHeaders()
    const pageSections = sections?.map((section, index) => {
        return {
            ...section,
            data: sectionData[index],
        }
    })
    try {
        const res = await axios.post(
            heyBioPageUrl,
            {
                ...themeData,
                profileId: profileData.heyId,
                properties: properties,
                sections: pageSections,
            },
            headers
        )

        if (res.data.model) {
            setPageId(res.data.model.id)
            return res.data.data
        } else return null
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const updateHeyPage = async () => {
    const { sectionData, sections, theme, themeVariables, profileData, pageId } =
        getState().HeyBioReducer
    const themeData = {
        profileId: profileData.heyId,
        theme: themeVariables.theme,
        background: theme.background,
        fontType: themeVariables.fontType,
        bannerType: themeVariables.bannerType,
        linkType: themeVariables.linkType,
    }

    let properties = {
        socialType: themeVariables.socialType,
        ...themeVariables.properties,
    }

    const headers = getHeaders()
    const pageSections = sections?.map((section, index) => {
        return {
            ...section,
            data: sectionData[section.id],
            displayOrder: index + 1,
        }
    })
    try {
        const res = await axios.put(
            heyBioPageUrl,
            {
                ...themeData,
                id: pageId,
                profileId: profileData.heyId,
                properties: properties,
                sections: pageSections,
            },
            headers
        )

        if (res.data.model) {
            setFullTheme(res.data.model, true)

            createMessage("success", "Page Updated Successfully")
            return res.data.model
        } else {
            createMessage("danger", res.data.msg)
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

const updateSectionOrdering = async (
    pageId,
    heyId,
    themeData,
    properties,
    pageSections
) => {
    try {
        const headers = getHeaders()
        const res = await axios.put(
            heyBioPageUrl,
            {
                ...themeData,
                id: pageId,
                profileId: heyId,
                properties: properties,
                sections: pageSections,
            },
            headers
        )

        return res
    } catch (err) {
        console.log(err)
    }
}

export const swapSections = async (sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex) return

    const { sectionData, sections, theme, themeVariables, profileData, pageId } =
        getState().HeyBioReducer
    const themeData = {
        profileId: profileData.heyId,
        theme: themeVariables.theme,
        background: theme.background,
        fontType: themeVariables.fontType,
        bannerType: themeVariables.bannerType,
        linkType: themeVariables.linkType,
    }

    let properties = {
        socialType: themeVariables.socialType,
        ...themeVariables.properties,
    }

    const headers = getHeaders()
    let pageSections = sections?.map((section, index) => {
        return {
            ...section,
            data: sectionData[section.id],
        }
    })

    pageSections = swapElements(pageSections, sourceIndex, destinationIndex)
    pageSections = pageSections.map((item, idx) => {
        return {
            ...item,
            displayOrder: idx + 1,
        }
    })
    let sectionsBackup = [...getState().HeyBioReducer.sections]

    dispatch({ type: SET_SECTIONS, payload: pageSections })
    pageSections = pageSections.map((item, idx) => {
        return {
            ...item,
            data: sectionData[item.id],
        }
    })

    try {
        const res = await axios.put(
            heyBioPageUrl,
            {
                ...themeData,
                id: pageId,
                profileId: profileData.heyId,
                properties: properties,
                sections: pageSections,
            },
            headers
        )

        if (res.data.code === 200) {
            createMessage("success", "Sections Swapped Successfully")
            return res.data.model
        } else {
            dispatch({ type: SET_SECTIONS, payload: sectionsBackup })
            createMessage("danger", "Section Swap Failed!")
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const insertSection = async (index, sectionToBeInserted) => {
    const { sectionData, sections, theme, themeVariables, profileData, pageId } =
        getState().HeyBioReducer
    const themeData = {
        profileId: profileData.heyId,
        theme: themeVariables.theme,
        background: theme.background,
        fontType: themeVariables.fontType,
        bannerType: themeVariables.bannerType,
        linkType: themeVariables.linkType,
    }

    let properties = {
        socialType: themeVariables.socialType,
        ...themeVariables.properties,
    }

    const headers = getHeaders()
    let pageSections = sections?.map((section, index) => {
        return {
            ...section,
            data: sectionData[section.id],
        }
    })

    pageSections.splice(index, 0, sectionToBeInserted)
    pageSections = pageSections.slice(0, pageSections.length - 1)
    pageSections = pageSections.map((item, idx) => {
        return {
            ...item,
            displayOrder: idx + 1,
        }
    })
    let sectionsBackup = [...sections]

    dispatch({ type: SET_SECTIONS, payload: pageSections })
    pageSections = pageSections.map((item, idx) => {
        return {
            ...item,
            data: sectionData[item.id],
        }
    })

    try {
        const res = await axios.put(
            heyBioPageUrl,
            {
                ...themeData,
                id: pageId,
                profileId: profileData.heyId,
                properties: properties,
                sections: pageSections,
            },
            headers
        )

        if (res.data.code === 200) {
            createMessage("success", "Sections Swapped Successfully")
            return res.data.model
        } else {
            dispatch({ type: SET_SECTIONS, payload: sectionsBackup })
            createMessage("danger", "Section Swap Failed!")
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const getHeyPageByHeyId = async () => {
    const { profileData } = getState().HeyBioReducer

    const headers = getHeaders()
    headers.params = { profileId: profileData.heyId }

    try {
        const res = await axios.get(heyBioPageUrl, headers)
        if (res.data.model.length === 0) {
            await addHeyPage()
        } else if (res.data.model) {
            dispatch({ type: SET_PAGE_ID, payload: res.data.model[0].id })
            const setAPITheme = true
            setFullTheme(res.data.model[0], setAPITheme)

            const sections = res.data.model[0].sections?.map((section) => {
                return {
                    name: section.name,
                    type: section.type,
                    id: section.id,
                }
            })

            const sectiondataObj = {}
            const t = res.data.model[0].sections?.map((section, index) => {
                sectiondataObj[section.id] = section.data
            })

            dispatch({ type: SET_SECTIONS, payload: sections })
            dispatch({ type: SET_SECTION_DATA, payload: sectiondataObj })
            return res.data.model[0]
        } else return null
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const getHeyPageByPageId = async () => {
    const { pageId } = getState().HeyBioReducer

    const headers = getHeaders()
    headers.params = { id: pageId }
    try {
        const res = await axios.get(heyBioPageUrl, headers)
        if (res.data.model) {
            const setAPITheme = true
            setFullTheme(res.data.model, setAPITheme)

            const sections = res.data.model.sections
                ?.map((section, idx) => {
                    return {
                        name: section.name,
                        type: section.type,
                        displayOrder: section.displayOrder ?? idx + 1,
                        id: section.id,
                    }
                })
                .sort((a, b) => {
                    return a.displayOrder < b.displayOrder
                        ? -1
                        : a.displayOrder === b.displayOrder
                        ? 0
                        : 1
                })

            const sectiondataObj = {}
            const t = res.data.model.sections?.map((section, index) => {
                const sectData = section.data?.map((item, idx) => {
                    return {
                        ...item,
                        ...item.properties,
                        oldId: idx, // TODO:: This is a temporary fix for uniquely identifying a link as backend doesnt provide a ID for links. Need a more reliable entity other than indexes and sizes
                    }
                })
                sectiondataObj[section.id] = sectData ?? []
            })

            dispatch({ type: SET_SECTIONS, payload: sections })
            dispatch({ type: SET_SECTION_DATA, payload: sectiondataObj })
            return res.data.model
        } else return null
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const getHeyBioProfileByHeyIdPublic = async (id) => {
    if (!id) return null
    dispatch({ type: SET_RELATED_ID, payload: id })
    const headers = getHeaders()
    headers.params = {
        hey_id: id,
    }
    const res = await heyBio.get("public/profile", headers)

    if (res.data.model) {
        const data = res.data.model
        let page = data.pages[0]

        setHeyProfileId(data.id)
        setHeyName(data.name)
        setHeyDescription(data.description)
        setHeyImage(data.image)

        dispatch({ type: SET_PAGE_ID, payload: page?.id })
        setFullTheme(page, true)

        const sections = page?.sections
            ?.map((section, idx) => ({
                name: section.name,
                type: section.type,
                displayOrder: section.displayOrder ?? idx + 1,
                id: section.id,
            }))
            .sort((a, b) => {
                return a.displayOrder < b.displayOrder
                    ? -1
                    : a.displayOrder === b.displayOrder
                    ? 0
                    : 1
            })

        const sectiondataObj = {}

        page?.sections &&
            page.sections.forEach((section, index) => {
                const sectData = section.data?.map((sectionData, idx) => ({
                    ...sectionData,
                    ...sectionData.properties,
                    oldId: idx,
                }))
                sectiondataObj[section.id] = sectData ?? []
            })

        dispatch({ type: SET_SECTIONS, payload: sections })
        dispatch({ type: SET_SECTION_DATA, payload: sectiondataObj })

        setHeyBioLoading(false)
        return data
    } else {
        setHeyBioLoading(false)
        return null
    }
}

export const addHeyPageSection = async (data, idx) => {
    const { sectionData, sections, profileData, pageId } = getState().HeyBioReducer
    const sectionIdx = sections ? (idx ? idx : sections.length) : 1
    const displayOrder = sections.length + 1
    const icon = null

    const headers = getHeaders()
    headers.params = { id: pageId }
    try {
        const res = await axios.post(
            heyBioPageUrl,
            {
                name: data.name,
                type: data.type,
                pageId: pageId,
                displayOrder: displayOrder,
                icon: icon,
                data: [],
            },
            headers
        )

        if (res.data.model) {
            data.id = res.data.model.id
            addNewSection(data)
            const successFullSwap = await insertSection(sectionIdx, data)
            if (successFullSwap) {
                createMessage("success", "Section Added Successfully")
                return res.data.model
            } else {
                createMessage("danger", res.data.msg)
                return null
            }
        } else {
            createMessage("danger", res.data.msg)
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

const updateSectionData = async (pageId, sectionId, section, data) => {
    const headers = getHeaders()
    headers.params = { id: pageId }

    const requestBody = {
        id: parseInt(sectionId),
        name: section.name,
        type: section.type,
        displayOrder: section.displayOrder,
        icon: null, // For now
        data: data,
    }

    try {
        const res = await axios.put(heyBioPageUrl, requestBody, headers)

        return res
    } catch (err) {
        console.log(err)
    }
}

export const updateHeyPageSection = async (data, linkIdx) => {
    const { sectionData, sections, profileData, pageId } = getState().HeyBioReducer
    const section_id = data.sectionId
    const section_idx = data.sectionIdx

    let displayOrder =
        linkIdx === null
            ? !sectionData[section_id]
                ? 1
                : sectionData[section_id].length + 1
            : linkIdx + 1
    let properties = {}

    const newData = {
        title: data?.args?.title ?? "",
        link: data?.args?.link ?? "",
        description: data?.args?.description ?? "",
        image: data?.args?.image ?? null,
        displayOrder: displayOrder,
    }

    Object.keys(data.args).map((key) => {
        if (!newData.hasOwnProperty(key)) properties[key] = data?.args?.[key]
    })

    newData.properties = properties

    let sendData = sectionData[section_id]?.map((item, index) => {
        return {
            ...item,
            displayOrder: index + 1,
        }
    }) ?? []
    if (linkIdx === null) {
        console.log(sectionData, section_id, sectionData[section_id])
        sendData.push({
            ...newData,
            oldId: sectionData[section_id].length ?? 0, // TODO:: This is a temporary fix for uniquely identifying a link as backend doesnt provide a ID for links. Need a more reliable entity other than indexes and sizes
        })
    } else {
        sendData[linkIdx] = newData
    }

    try {
        const res = await updateSectionData(
            pageId,
            section_id,
            sections[section_idx],
            sendData
        )

        if (res?.data?.model) {
            addDataToSection(
                linkIdx !== null
                    ? {
                          ...sendData[linkIdx],
                          sectionId: data.sectionId,
                          sectionIdx: data.sectionIdx,
                          type: data.type,
                      }
                    : {
                          ...sendData[sendData.length - 1],
                          sectionId: data.sectionId,
                          sectionIdx: data.sectionIdx,
                          type: data.type,
                      },
                linkIdx
            )

            createMessage("success", "Section Updated Successfully")
            return res.data.model
        } else {
            createMessage("danger", res.data.msg)
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

const swapElements = (array, index1, index2) => {
    const temp = array[index1]
    array[index1] = array[index2]
    array[index2] = temp
    return array
}

export const swapLinks = async (
    sourceSectionId,
    sourceLinkIndex,
    destinationSectionId,
    destinationLinkIndex
) => {
    const { sectionData, sections, profileData, pageId } = getState().HeyBioReducer
    const sectionDataBackup = { ...sectionData }

    if (sourceSectionId === destinationSectionId) {
        if (sourceLinkIndex === destinationLinkIndex) return
        let sectionIdx
        sections.forEach((section, index) => {
            if (section.id === parseInt(sourceSectionId)) sectionIdx = index
        })
        let sendData = [...sectionData[sourceSectionId]]

        sendData = swapElements(sendData, sourceLinkIndex, destinationLinkIndex)
        sendData = sendData.map((item, idx) => {
            return {
                ...item,
                displayOrder: idx + 1,
            }
        })
        let sectionDataObj = { ...sectionData }
        sectionDataObj[sourceSectionId] = sendData
        dispatch({ type: SET_SECTION_DATA, payload: sectionDataObj })

        try {
            const res = await updateSectionData(
                pageId,
                sourceSectionId,
                sections[sectionIdx],
                sendData
            )
            if (res?.data?.code === 200) {
                createMessage("success", "Section Updated Successfully")
                return res.data.model
            } else {
                dispatch({ type: SET_SECTION_DATA, payload: sectionDataBackup })
                createMessage("danger", res.data.msg)
                return null
            }
        } catch (err) {
            console.log("Error", err)
            return null
        }
    } else {
        let sourceSectionIdx
        let destinationSectionIdx
        sections.forEach((section, index) => {
            if (section.id === parseInt(sourceSectionId)) sourceSectionIdx = index
            if (section.id === parseInt(destinationSectionId))
                destinationSectionIdx = index
        })
        let sourceSendData = [...sectionData[sourceSectionId]]
        let destinationSendData = [...sectionData[destinationSectionId]]

        destinationSendData.splice(
            destinationLinkIndex,
            0,
            sourceSendData[sourceLinkIndex]
        )
        destinationSendData = destinationSendData.map((item, idx) => {
            return {
                ...item,
                displayOrder: idx + 1,
            }
        })
        sourceSendData.splice(sourceLinkIndex, 1)
        sourceSendData = sourceSendData.map((item, idx) => {
            return {
                ...item,
                displayOrder: idx + 1,
            }
        })

        let sectionDataObj = { ...sectionData }
        sectionDataObj[sourceSectionId] = sourceSendData
        sectionDataObj[destinationSectionId] = destinationSendData
        dispatch({ type: SET_SECTION_DATA, payload: sectionDataObj })

        try {
            const res = await Promise.all([
                updateSectionData(
                    pageId,
                    sourceSectionId,
                    sections[sourceSectionIdx],
                    sourceSendData
                ),
                updateSectionData(
                    pageId,
                    destinationSectionId,
                    sections[destinationSectionIdx],
                    destinationSendData
                ),
            ])
            if (res[0]?.data.code === 200 && res[1]?.data.code === 200) {
                createMessage("success", "Links Swapped Successfully")
            } else {
                dispatch({ type: SET_SECTION_DATA, payload: sectionDataBackup })
                createMessage("danger", "Link Swap Failed!")
            }
        } catch (err) {
            console.log("Error", err)
            return null
        }
    }
}

export const updateHeyPageSocialSection = async (linksArray) => {
    const { sectionData, sections, profileData, pageId } = getState().HeyBioReducer
    const section_id = linksArray[0].sectionId
    const section_idx = linksArray[0].sectionIdx
    let baseLength = !sectionData[section_idx]
        ? 1
        : sectionData[section_idx].length + 1
    let sendData = []

    for (let i = 0; i < linksArray.length; i++) {
        const data = linksArray[i]
        let displayOrder = baseLength + i
        let properties = {}

        const newData = {
            title: data?.args?.title ?? "",
            link: data?.args?.link ?? "",
            description: data?.args?.description ?? "",
            image: data?.args?.image ?? null,
            displayOrder: displayOrder,
        }

        Object.keys(data.args).map((key) => {
            if (!newData.hasOwnProperty(key)) properties[key] = data?.args?.[key]
        })
        newData.properties = properties
        sendData.push(newData)
    }

    const reqBody = {
        id: section_id,
        name: sections[section_idx].name,
        type: sections[section_idx].type,
        displayOrder: section_idx + 1,
        icon: null, // For now
        data: sendData,
    }

    const headers = getHeaders()
    headers.params = { id: pageId }
    try {
        const res = await axios.put(heyBioPageUrl, reqBody, headers)
        if (res.data.model) {
            addSocialDataToSection(res.data.model.data, section_idx)
            createMessage("success", "Section Updated Successfully")
            return res.data.model
        } else {
            createMessage("danger", res.data.msg)
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const deleteHeyPageSectionData = async (section_id, section_idx, data_id) => {
    const { sectionData, sections, profileData, pageId } = getState().HeyBioReducer

    let filteredData = sectionData[section_id]?.filter((item, index) => {
        return index !== data_id
    })

    filteredData = filteredData.map((data, i) => {
        return {
            ...data,
            displayOrder: i + 1,
        }
    })

    const reqBody = {
        id: section_id,
        name: sections[section_idx].name,
        type: sections[section_idx].type,
        displayOrder: section_idx + 1, // doing by index, will changes when using id
        icon: null, // For now
        data: filteredData,
    }
    const headers = getHeaders()
    headers.params = { id: pageId }
    try {
        const res = await axios.put(heyBioPageUrl, reqBody, headers)
        if (res.data.model) {
            createMessage("success", "Section Data Deleted Successfully")
            return res.data.model
        } else {
            createMessage("danger", res.data.msg)
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const deleteHeyPageSectionHandler = async (sectionId, sectionIdx) => {
    const {
        pageId,
        sections,
        sectionData,
        heyId,
        theme,
        themeVariables,
        profileData,
    } = getState().HeyBioReducer
    const headers = getHeaders()
    headers.params = {
        id: pageId,
        section_id: sectionId,
    }
    try {
        const res = await axios.delete(heyBioPageUrl, headers)
        if (res.data.code === 200) {
            let newSections = sections.filter((section, index) => {
                return section.id !== sectionId
            })
            newSections = newSections.map((section, idx) => {
                return {
                    ...section,
                    data: sectionData[section.id],
                    displayOrder: idx + 1,
                }
            })

            const themeData = {
                profileId: profileData.heyId,
                theme: themeVariables.theme,
                background: theme.background,
                fontType: themeVariables.fontType,
                bannerType: themeVariables.bannerType,
                linkType: themeVariables.linkType,
            }

            let properties = {
                socialType: themeVariables.socialType,
                ...themeVariables.properties,
            }
            const orderingResult = await updateSectionOrdering(
                pageId,
                heyId,
                themeData,
                properties,
                newSections
            )

            if (orderingResult?.data?.code === 200) {
                let newSectionData = { ...sectionData }
                delete newSectionData[sectionId]

                dispatch({ type: SET_SECTIONS, payload: newSections })
                dispatch({ type: SET_SECTION_DATA, payload: newSectionData })

                createMessage("success", "Section Deleted Successfully")
                return true
            } else {
                createMessage("danger", res.data.msg)
                return null
            }
        } else {
            createMessage("danger", res.data.msg)
            return null
        }
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const searchHeyId = async (tempHeyId) => {
    const { profileData } = getState().HeyBioReducer
    const headers = getHeaders()
    headers.params = {
        hey_id: tempHeyId ?? profileData.heyId,
        action: "search",
    }
    try {
        const res = await axios.get(heyBioProfileUrl, headers)
        if (res.data.msg === "success") return res.data.model
        else return null
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const deleteHeyPage = async () => {
    const { profileData } = getState().HeyBioReducer
    const headers = getHeaders()
    headers.params = {
        id: profileData.heyId,
    }
    try {
        const res = await axios.delete(heyBioPageUrl, headers)
        if (res.msg === "success") return true
        else return null
    } catch (err) {
        console.log("Error", err)
        return null
    }
}

export const setFullTheme = (themeObj, setAPITheme) => {
    addHeyTheme({ type: THEME, data: themeObj.theme, api: setAPITheme })
    addHeyTheme({ type: FONT_FAMILY, data: themeObj.fontType, api: setAPITheme })
    addHeyTheme({ type: LINK, data: themeObj.linkType, api: setAPITheme })
    addHeyTheme({ type: BANNER, data: themeObj.bannerType, api: setAPITheme })
    addHeyTheme({ type: BACKGROUND, data: themeObj.background, api: setAPITheme })
    addHeyTheme({
        type: SOCIALS,
        data: themeObj.properties?.socialType ?? themeObj.socialType ?? "BLACK",
        api: setAPITheme,
    })
    addHeyTheme({
        type: PROPERTIES,
        data: themeObj.properties ?? {},
        api: setAPITheme,
    })
}
