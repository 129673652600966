import React from "react"

import { ReactComponent as BehanceBlack} from "../../assets/SocialIcons/black-icons/Behance-black.svg"
import { ReactComponent as DribbleBlack} from "../../assets/SocialIcons/black-icons/dribble-black.svg"
import { ReactComponent as FaceBookBlack} from "../../assets/SocialIcons/black-icons/facebook-black.svg"
import { ReactComponent as InstagramBlack} from "../../assets/SocialIcons/black-icons/instagram-black.svg"
import { ReactComponent as LinkedinBlack} from "../../assets/SocialIcons/black-icons/Linkedin-black.svg"
import { ReactComponent as MailBlack} from "../../assets/SocialIcons/black-icons/mail-black.svg"
import { ReactComponent as MessengerBlack} from "../../assets/SocialIcons/black-icons/messenger-black.svg"
import { ReactComponent as PinterestBlack} from "../../assets/SocialIcons/black-icons/pinterest-black.svg"
import { ReactComponent as RedditBlack} from "../../assets/SocialIcons/black-icons/reddit-black.svg"
import { ReactComponent as SnapChatBlack} from "../../assets/SocialIcons/black-icons/snapchat-black.svg"
import { ReactComponent as SoundCloudBlack} from "../../assets/SocialIcons/black-icons/soundcloud-black.svg"
import { ReactComponent as SpotifyBlack} from "../../assets/SocialIcons/black-icons/spotify-black.svg"
import { ReactComponent as TelegramBlack} from "../../assets/SocialIcons/black-icons/telegram-black.svg"
import { ReactComponent as TikTokBlack} from "../../assets/SocialIcons/black-icons/tiktok-black.svg"
import { ReactComponent as TwitterBlack} from "../../assets/SocialIcons/black-icons/twitter-black.svg"
import { ReactComponent as WhatsappBlack} from "../../assets/SocialIcons/black-icons/whatsapp-black.svg"
import { ReactComponent as YoutubeBlack} from "../../assets/SocialIcons/black-icons/youtube-black.svg"

import { ReactComponent as BehanceColor} from "../../assets/SocialIcons/colored-icons/Behance-color.svg"
import { ReactComponent as DribbleColor} from "../../assets/SocialIcons/colored-icons/dribble-color.svg"
import { ReactComponent as FaceBookColor} from "../../assets/SocialIcons/colored-icons/facebook-color.svg"
import { ReactComponent as InstagramColor} from "../../assets/SocialIcons/colored-icons/instagram-color.svg"
import { ReactComponent as LinkedinColor} from "../../assets/SocialIcons/colored-icons/Linkedin-color.svg"
import { ReactComponent as MailColor} from "../../assets/SocialIcons/colored-icons/mail-color.svg"
import { ReactComponent as MessengerColor} from "../../assets/SocialIcons/colored-icons/messenger-color.svg"
import { ReactComponent as PinterestColor} from "../../assets/SocialIcons/colored-icons/pinterest-color.svg"
import { ReactComponent as RedditColor} from "../../assets/SocialIcons/colored-icons/reddit-color.svg"
import { ReactComponent as SnapChatColor} from "../../assets/SocialIcons/colored-icons/snapchat-color.svg"
import { ReactComponent as SoundCloudColor} from "../../assets/SocialIcons/colored-icons/soundcloud-color.svg"
import { ReactComponent as SpotifyColor} from "../../assets/SocialIcons/colored-icons/spotify-color.svg"
import { ReactComponent as TelegramColor} from "../../assets/SocialIcons/colored-icons/telegram-color.svg"
import { ReactComponent as TikTokColor} from "../../assets/SocialIcons/colored-icons/tiktok-color.svg"
import { ReactComponent as TwitterColor} from "../../assets/SocialIcons/colored-icons/twitter-color.svg"
import { ReactComponent as WhatsappColor} from "../../assets/SocialIcons/colored-icons/whatsapp-color.svg"
import { ReactComponent as YoutubeColor} from "../../assets/SocialIcons/colored-icons/youtube-color.svg"

import { ReactComponent as BehanceGrey} from "../../assets/SocialIcons/grey-icons/Behance-grey.svg"
import { ReactComponent as DribbleGrey} from "../../assets/SocialIcons/grey-icons/dribble-grey.svg"
import { ReactComponent as FaceBookGrey} from "../../assets/SocialIcons/grey-icons/facebook-grey.svg"
import { ReactComponent as InstagramGrey} from "../../assets/SocialIcons/grey-icons/instagram-grey.svg"
import { ReactComponent as LinkedinGrey} from "../../assets/SocialIcons/grey-icons/Linkedin-grey.svg"
import { ReactComponent as MailGrey} from "../../assets/SocialIcons/grey-icons/mail-grey.svg"
import { ReactComponent as MessengerGrey} from "../../assets/SocialIcons/grey-icons/messenger-grey.svg"
import { ReactComponent as PinterestGrey} from "../../assets/SocialIcons/grey-icons/pinterest-grey.svg"
import { ReactComponent as RedditGrey} from "../../assets/SocialIcons/grey-icons/reddit-grey.svg"
import { ReactComponent as SnapChatGrey} from "../../assets/SocialIcons/grey-icons/snapchat-grey.svg"
import { ReactComponent as SoundCloudGrey} from "../../assets/SocialIcons/grey-icons/soundcloud-grey.svg"
import { ReactComponent as SpotifyGrey} from "../../assets/SocialIcons/grey-icons/spotify-grey.svg"
import { ReactComponent as TelegramGrey} from "../../assets/SocialIcons/grey-icons/telegram-grey.svg"
import { ReactComponent as TikTokGrey} from "../../assets/SocialIcons/grey-icons/tiktok-grey.svg"
import { ReactComponent as TwitterGrey} from "../../assets/SocialIcons/grey-icons/twitter-grey.svg"
import { ReactComponent as WhatsappGrey} from "../../assets/SocialIcons/grey-icons/whatsapp-grey.svg"
import { ReactComponent as YoutubeGrey} from "../../assets/SocialIcons/grey-icons/youtube-grey.svg"

import { ReactComponent as BehanceWhite} from "../../assets/SocialIcons/white-icons/Behance-white.svg"
import { ReactComponent as DribbleWhite} from "../../assets/SocialIcons/white-icons/dribble-white.svg"
import { ReactComponent as FaceBookWhite} from "../../assets/SocialIcons/white-icons/facebook-white.svg"
import { ReactComponent as InstagramWhite} from "../../assets/SocialIcons/white-icons/instagram-white.svg"
import { ReactComponent as LinkedinWhite} from "../../assets/SocialIcons/white-icons/Linkedin-white.svg"
import { ReactComponent as MailWhite} from "../../assets/SocialIcons/white-icons/mail-white.svg"
import { ReactComponent as MessengerWhite} from "../../assets/SocialIcons/white-icons/messenger-white.svg"
import { ReactComponent as PinterestWhite} from "../../assets/SocialIcons/white-icons/pinterest-white.svg"
import { ReactComponent as RedditWhite} from "../../assets/SocialIcons/white-icons/reddit-white.svg"
import { ReactComponent as SnapChatWhite} from "../../assets/SocialIcons/white-icons/snapchat-white.svg"
import { ReactComponent as SoundCloudWhite} from "../../assets/SocialIcons/white-icons/soundcloud-white.svg"
import { ReactComponent as SpotifyWhite} from "../../assets/SocialIcons/white-icons/spotify-white.svg"
import { ReactComponent as TelegramWhite} from "../../assets/SocialIcons/white-icons/telegram-white.svg"
import { ReactComponent as TikTokWhite} from "../../assets/SocialIcons/white-icons/tiktok-white.svg"
import { ReactComponent as TwitterWhite} from "../../assets/SocialIcons/white-icons/twitter-white.svg"
import { ReactComponent as WhatsappWhite} from "../../assets/SocialIcons/white-icons/whatsapp-white.svg"
import { ReactComponent as YoutubeWhite} from "../../assets/SocialIcons/white-icons/youtube-white.svg"


export const socialLinkData = {
    BLACK: {
        behance: <BehanceBlack/>,
        dribble: <DribbleBlack/>,
        facebook: <FaceBookBlack/>,
        instagram: <InstagramBlack/>,
        linkedin: <LinkedinBlack/>,
        mail: <MailBlack/>,
        messenger: <MessengerBlack/>,
        pinterest: <PinterestBlack/>,
        reddit: <RedditBlack/>,
        snapchat: <SnapChatBlack/>,
        soundcloud: <SoundCloudBlack/>,
        spotify: <SpotifyBlack/>,
        telegram: <TelegramBlack/>,
        tiktok: <TikTokBlack/>,
        twitter: <TwitterBlack/>,
        whatsapp: <WhatsappBlack/>,
        youtube: <YoutubeBlack/>
    },
    COLOR: {
        behance: <BehanceColor/>,
        dribble: <DribbleColor/>,
        facebook: <FaceBookColor/>,
        instagram: <InstagramColor/>,
        linkedin: <LinkedinColor/>,
        mail: <MailColor/>,
        messenger: <MessengerColor/>,
        pinterest: <PinterestColor/>,
        reddit: <RedditColor/>,
        snapchat: <SnapChatColor/>,
        soundcloud: <SoundCloudColor/>,
        spotify: <SpotifyColor/>,
        telegram: <TelegramColor/>,
        tiktok: <TikTokColor/>,
        twitter: <TwitterColor/>,
        whatsapp: <WhatsappColor/>,
        youtube: <YoutubeColor/>
    },
    GREY: {
        behance: <BehanceGrey/>,
        dribble: <DribbleGrey/>,
        facebook: <FaceBookGrey/>,
        instagram: <InstagramGrey/>,
        linkedin: <LinkedinGrey/>,
        mail: <MailGrey/>,
        messenger: <MessengerGrey/>,
        pinterest: <PinterestGrey/>,
        reddit: <RedditGrey/>,
        snapchat: <SnapChatGrey/>,
        soundcloud: <SoundCloudGrey/>,
        spotify: <SpotifyGrey/>,
        telegram: <TelegramGrey/>,
        tiktok: <TikTokGrey/>,
        twitter: <TwitterGrey/>,
        whatsapp: <WhatsappGrey/>,
        youtube: <YoutubeGrey/>
    },
    WHITE: {
        behance: <BehanceWhite/>,
        dribble: <DribbleWhite/>,
        facebook: <FaceBookWhite/>,
        instagram: <InstagramWhite/>,
        linkedin: <LinkedinWhite/>,
        mail: <MailWhite/>,
        messenger: <MessengerWhite/>,
        pinterest: <PinterestWhite/>,
        reddit: <RedditWhite/>,
        snapchat: <SnapChatWhite/>,
        soundcloud: <SoundCloudWhite/>,
        spotify: <SpotifyWhite/>,
        telegram: <TelegramWhite/>,
        tiktok: <TikTokWhite/>,
        twitter: <TwitterWhite/>,
        whatsapp: <WhatsappWhite/>,
        youtube: <YoutubeWhite/>
    },
}