import React from 'react'

function RedirectMainsite() {
    window.location.replace(
        `https://awesomesuite.com/social/hey-bio`
    )
  return (
    <div></div>
  )
}

export default RedirectMainsite