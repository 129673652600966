import {CREATE_MESSAGE, CLEAR_MESSAGE} from "../ActionType/MessageActionTypes";

import store from "../store";

const { dispatch } = store;

export const createMessage = (type, message) => {
    dispatch({
      type: CREATE_MESSAGE,
      payload: { type, message },
    });
  };

export const clearMessage = () => {
    dispatch({type: CLEAR_MESSAGE});
}