import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

function MetaTitle({metaTitle}) {
  console.log(metaTitle)
  return (
    <Helmet>
        <title>{metaTitle}</title>
    </Helmet>
  )
}

const mapStateToProps = ({HeyBioReducer}) =>{
    return {
        metaTitle: HeyBioReducer.metaTitle,
    }
}

export default connect(mapStateToProps)(MetaTitle);