import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  heyTSocial:{
    width: '100%',
    display: "flex",
    justifyContent: "center",
  },
  socialLink:{
    textDecoration: 'none',
    color: 'inherit',
    margin: '0.5rem 1rem',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    '& svg':{
      minWidth: '30px',
      minHeight: '30px',
      width: "30px",
      height: "30px",
    }
  },
  socialWrapper: {
    padding: '12px 1rem',
    display: 'flex',
    maxWidth: "30rem",
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  }    
})

export default useStyles;