import React from "react";
import "./App.css";

import { Provider } from "react-redux";
import store from "./store";
import { Route, Switch } from "react-router-dom";
import { FullPageView } from "shared";
import { ThemeContext } from "@awesomesuite-frontend/awesome-nebula";
import { setHeyId } from "Actions/HeyBioActions/HeyActions";
import RedirectMainsite from "Pages/RedirectPages/RedirectMainsite";
import MetaTitle from "Pages/HeyBio/MetaTitle";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Switch>
          <ThemeContext>
            <Route path={"/"} exact render={() => <RedirectMainsite/>} />
            <Route 
              path={"/:heyId"} 
              exact 
              render={(routeProps) => {
                setHeyId(routeProps.match.params.heyId)
                return <FullPageView 
                  heyId={routeProps.match.params.heyId}
                />
              }} 
            />
            <MetaTitle/>
          </ThemeContext>
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
