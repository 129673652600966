import { createUseStyles } from "react-jss"
const useStyles = createUseStyles({
    heyTLink: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 12px",
        textDecoration: "none",
        margin: "8px 0px",
        maxWidth: "700px",
        width: "100%",
        overflowWrap: "break-word",
        border: ({ theme, themeVariables }) =>
            `${themeVariables.linkType === "ROUNDED_2" ||
                themeVariables.linkType === "SHARP_2"
                ? 0
                : themeVariables.properties.borderThickness ?? "1"
            }px solid ${themeVariables.properties.borderColor || theme.borderColor1 || "black"}`,
        background: ({ theme, btnColor }) => btnColor || theme.accentColor1,
        borderRadius: ({ theme }) => theme.linkRadius,
        boxShadow: ({ theme }) => theme.linkShadow,
        transition: "transform .15s cubic-bezier(.17,.67,.29,2.71) 0s",
        "&:hover": {
            transform: "translate3d(0px, 0px, 0px) scale(1.015)"
        },      
        "@media (min-width: 20rem)": {
            minHeight: "3.5rem",
            fontSize: "1.125rem",
        }
    },
    linkImage: {
        minWidth: "44px",
        width: "44px",
        minHeight: "44px",
        height: "44px",
        borderRadius: ({ theme }) => theme.linkRadius,
        display: "block",
        objectFit: "cover",
        marginRight: "8px",
    },
    heyTtitle: {
        maxWidth: "92%",
        textAlign: "left",
        backgroundColor: ({ theme, btnColor }) => btnColor ?? theme.accentColor1,
        textDecoration: "none",
        color: ({ theme }) => theme.textColor1,
        fontFamily: ({ themeVariables }) => themeVariables?.fontType,
        overflowX: "hidden",
        margin: "0 auto",
        fontWeight: "500",
    },
})
export default useStyles
