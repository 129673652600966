import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    embedWrapper: {
        width: "100%",
        border: ({theme})=> theme.bannerBorder,
        borderColor: ({theme}) => theme.borderColor2,
        background: ({theme})=> theme.bannerBG ?? theme.secondaryColor,
        padding: "0.5rem 0.6rem",
        margin: "0.5rem 0",
        borderRadius: ({theme}) => theme.bannerRadius,
        boxShadow: ({theme}) => theme.bannerShadow,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    iframeTag: {
        width: "100%",
        border: "none",
        borderRadius: "12px",

        "@media (min-width: 1200px)": {
            height: ({type, fromMobilePreview}) => type === "youtube" ? fromMobilePreview ? "160px" : "320px" : "160px",
        },
        "@media (max-width: 1200px)": {
            height: ({type, fromMobilePreview}) => type === "youtube" ? fromMobilePreview ? "160px" : "350px" : "160px",
        },
        "@media (max-width: 800px)": {
            height: ({type, fromMobilePreview}) => type === "youtube" ? fromMobilePreview ? "160px" : "300px" : "160px",
        },
        "@media (max-width: 600px)": {
            height: ({type, fromMobilePreview}) => type === "youtube" ? fromMobilePreview ?  "160px" : "275px" : "160px",
        },
        "@media (max-width: 450px)": {
            height: ({type, fromMobilePreview}) => type === "youtube" ? fromMobilePreview ?  "160px" : "200px" : "160px",
        },
    },
    
});
export default useStyles;