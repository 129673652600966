import axios from "axios";

const getApiURLs = () => {
  const enviroment = process.env.REACT_APP_ENV;
  console.log("This is api : ", enviroment);
  switch (enviroment) {
    case "production":
      return prodApis;
    case "release":
      return releaseApis;
    case "dev":
      return devApis;
    case "dev-local":
      return devApis;
    default:
      return devApis;
  }
};

const prodApis = {
  USER_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/user-management-service",
  AUTH_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/user-management-service",
  ANALYTIC_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service/activity",
  TRANSACTION_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/payment-service",
  SESSIONS_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service",
  WORKSPACE_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/workspace-service",
  SUBSCRIPTION_API:
    "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/subscription-service",
  HEY_BIO_API: "https://g56270hogd.execute-api.ap-south-1.amazonaws.com/prod/hey-bio/hey",
};

const releaseApis = {
  USER_API:
    "https://lnvnzqhqc0.execute-api.us-west-1.amazonaws.com/default/user-management-service",
  AUTH_API:
    "https://lnvnzqhqc0.execute-api.us-west-1.amazonaws.com/default/user-management-service",
  ANALYTIC_API:
    "https://lnvnzqhqc0.execute-api.us-west-1.amazonaws.com/default/user-management-service",
  TRANSACTION_API:
    "https://lnvnzqhqc0.execute-api.us-west-1.amazonaws.com/default/payment-service",
  SESSIONS_API:
    "https://lnvnzqhqc0.execute-api.us-west-1.amazonaws.com/default/",
  WORKSPACE_API:
    "https://lnvnzqhqc0.execute-api.us-west-1.amazonaws.com/default/workspace-service",
  SUBSCRIPTION_API:
    "https://lnvnzqhqc0.execute-api.us-west-1.amazonaws.com/default/subscription-service",
  HEY_BIO_API:
    "https://39ci0514if.execute-api.us-east-2.amazonaws.com/sit/hey-bio/hey",
};

const devApis = {
  USER_API: "https://0umf7ix9s7.execute-api.ap-south-1.amazonaws.com/default",
  AUTH_API: "https://zc264xqbli.execute-api.ap-south-1.amazonaws.com/default",
  ANALYTIC_API:
    "https://4qlsk96mg3.execute-api.ap-south-1.amazonaws.com/default/activity",
  TRANSACTION_API:
    "https://x51g83npcb.execute-api.ap-south-1.amazonaws.com/default",
  SESSIONS_API:
    "https://4qlsk96mg3.execute-api.ap-south-1.amazonaws.com/default",
  WORKSPACE_API:
    "https://ret2ft1pgj.execute-api.ap-south-1.amazonaws.com/default",
  SUBSCRIPTION_API:
    "https://s22hl6eg16.execute-api.ap-south-1.amazonaws.com/default",
  HEY_BIO_API:
    "https://k5bt8i2w18.execute-api.ap-south-1.amazonaws.com/dev/hey-bio/hey",
};

export const APIS = getApiURLs();

export const TRANSACTION_API = APIS.TRANSACTION_API;

export const api = axios.create({
  baseURL: APIS.SESSIONS_API,
});

export const analyticApi = axios.create({
  baseURL: APIS.ANALYTIC_API,
});

export const heyBioApi = axios.create({
  baseURL: APIS.HEY_BIO_API,
});

export const heyBioProfileUrl = `${APIS.HEY_BIO_API}/profile`;
export const heyBioPageUrl = `${APIS.HEY_BIO_API}/page`;
export const heyBio = axios.create({
  baseURL: APIS.HEY_BIO_API,
});

// shopify 
export const shopifyService = "https://3xotq6g5y5.execute-api.ap-south-1.amazonaws.com";
