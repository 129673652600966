export const LOGIN_USER_BYPASS = "LOGIN_USER_BYPASS";
export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const CLEAN_ALL_USER_DATA  = "CLEAN_ALL_USER_DATA";
export const FORGOT_PASS_ADD_EMAIL  = "FORGOT_PASS_ADD_EMAIL";
export const FORGOT_PASS_ADD_OTP  = "FORGOT_PASS_ADD_USER";
export const FORGOT_PASS_ADD_TOKEN  = "FORGOT_PASS_ADD_TOKEN";
export const LOAD_USER_SESSION = "LOAD_USER_SESSION";
export const ADD_SIGNUP_DATA = "ADD_SIGNUP_DATA";
export const GET_ORGANIZATION_LOADING = "GET_ORGANIZATION_LOADING";
export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS";
export const GET_ORGANIZATION_FAILED = "GET_ORGANIZATION_FAILED";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const GET_ALL_USERS_IN_ORGANIZATION = "GET_ALL_USERS_IN_ORGANIZATION";
export const GET_ALL_USERS_IN_ORG_LOADING = "GET_ALL_USERS_IN_LOADING";
export const GET_ALL_USERS_IN_ORG_FAILED = "GET_ALL_USERS_IN_FAILED";
export const ADD_USER_ROLES = "ADD_USER_ROLES";