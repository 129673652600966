import React from "react"
import { Grid } from "@awesomesuite-frontend/awesome-nebula"
import useStyles from "./HeyLinkStyles"

const HeyLink = ({
    title,
    image,
    link,
    style,
    themeVariables,
    theme = "greyp",
    btnColor,
}) => {
    const classes = useStyles({
        theme: theme,
        themeVariables: themeVariables,
        image: image,
        btnColor: btnColor,
    })
    return (
        <a href={link} className={classes.heyTLink} style={{ ...style }}>
            {image && <img className={classes.linkImage} src={image}></img>}
            <Grid className={classes.heyTtitle}>{title}</Grid>
        </a>
    )
}

export default HeyLink
