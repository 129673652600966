import React, { useRef, useState, useEffect } from "react"
import { Grid } from "@awesomesuite-frontend/awesome-nebula"
import useStyles from "./HeyBannerStyles"

const verticalStyle = {
    minWidth: "220px",
    width: "220px",
    minHeight: "295px",
    height: "295px",
}

const horizontalStyle = {
    minWidth: "500px",
    minHeight: "200px",
    height: "200px",
}

const HeyBanner = ({
    title,
    description,
    btnText,
    btnColor,
    image,
    link = "/",
    data,
    theme = "greyp",
    themeVariables,
}) => {
    const overflowCheckerRef = useRef()
    const [isOverflow, setIsOverflow] = useState(false)

    useEffect(() => {
        if(title?.length + description?.length > 75){
          setIsOverflow(true)
        }
    }, [overflowCheckerRef , data])

    const classes = useStyles({
        btnColor: btnColor,
        theme: theme,
        themeVariables: themeVariables,
    })

    return (
        <Grid
            width={isOverflow ? "unset" : "220px"}
            height={isOverflow ? "200px" : "295px"}
            margin={8}
            className={classes.heyBannerWrapper}
            style={isOverflow ? { ...horizontalStyle } : { ...verticalStyle }}
        >
            <Grid
                height="100%"
                width="100%"
                direction="column"
                className={`
                ${classes.heyTbanner}  ${isOverflow && classes.heyTBannerHorizontal} 
                `}
            >
                <div
                    className={`
                    ${classes.heyTbannerImg}  ${
                        isOverflow && classes.heyTbannerImgHorizontal
                    }
                            
                    `}
                >
                    {image && <img src={image}></img>}
                </div>
                <div
                    className={`
                    ${classes.heyTbannerContent}  ${
                        isOverflow && classes.heyTbannerContentHorizontal
                    }
                            
                    `}
                    ref={overflowCheckerRef}
                >
                    <h3
                        className={`
                        ${classes.heyTbannerTitle} ${
                            isOverflow && classes.heyTBannerTitleHorizontal
                        }
                                
                        `}
                    >
                        {title}
                    </h3>
                    <p
                        className={`
                        ${classes.heyTbannerDes}  ${
                            isOverflow && classes.heyTbannerDesHorizontal
                        }
                                
                        `}
                    >
                        {description}
                    </p>
                    <a href={link} className={classes.heyTbannerBtn}>
                        {btnText || "Click"}
                    </a>
                </div>
            </Grid>
        </Grid>
    )
}

export default HeyBanner
